<template>
  <div class="custom-auth">
    <div class="header">
      <div class="left" />
      <div class="right">
        <el-button
          type="primary"
          size="mini"
          :loading="loading"
          @click="onRoleSave"
        >
          保存角色权限
        </el-button>
        <!-- <el-button type="danger" size="mini" @click="syncPermsToEnv">同步权限到当前环境</el-button> -->
      </div>
    </div>
    <div class="middle">
      <div class="role-list">
        <el-tabs v-model="actRoleId" tab-position="left" @tab-click="onTabClick">
          <el-tab-pane
            v-for="item in roleList"
            :key="item.id"
            :label="item.name"
            :name="item.id"
          />
        </el-tabs>
      </div>
      <div class="tree">
        <el-tree
          ref="tree"
          :default-checked-keys="defaultCheckedKeys"
          empty-text="暂无权限分组"
          :data="data"
          show-checkbox
          :props="defaultProps"
          :default-expand-all="true"
          :highlight-current="true"
          node-key="sectionId"
          :expand-on-click-node="false"
        >
          <template slot-scope="{ node }">
            <div class="tree-node">
              <div class="name" :title="node.label">{{ node.label }}</div>
            </div>
          </template>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
export default {
  name: 'Role',
  data() {
    return {
      data: [],
      list: [],
      defaultProps: {
        children: 'children',
        label: 'sectionName'
      },
      formDialogVisible: false,
      form: {
        type: '',
        name: '',
        id: ''
      },
      sourceNode: null,
      targetNode: null,
      editAuthvisible: false,
      actRoleId: '1',
      roleList: [],
      defaultCheckedKeys: [],
      loading: false
    }
  },
  mounted() {
    this.getRoleList().then(() => {
      this.getTreeList()
    })
  },
  methods: {
    // 获取角色列表
    async getRoleList() {
      try {
        const params = {
          page: 1,
          limit: 9999
        }
        const res = await this.$axios.get(this.$API.roleList, {params})
        this.roleList = res.map(item => {
          item.id = item.id.toString()
          return item
        })
        this.actRoleId = this.roleList[0].id
      } catch (error) {
        console.log(error)
      }
    },
    getTreeList() {
      const params = {
        roleId: this.actRoleId
      }
      this.$axios.get(this.$API.assignAblePermissionList, { params }).then(data => {
        this.data = cloneDeep(data)
        this.defaultCheckedKeys = this.getCheckedKeys(data)
      }).catch(err => {
        this.$message({
          type: 'error',
          message: `权限查询错误: ${err.message}`
        })
      })
    },
    // 保存当前角色权限
    onRoleSave() {
      this.$confirm('确定保存当前角色权限？', '提示').then(() => {
        this.loading = true
        let checks = this.$refs.tree.getCheckedKeys()
        this.$axios.post(this.$API.assignPermissionToRole, {
          roleId: this.actRoleId,
          permissionSectionIds: checks
        }).then(() => {
          this.$message.success('保存成功')
        }).catch(err => {
          this.$message.error(`保存失败: ${err.message}`)
        }).finally(() => {
          this.loading = false
        })
      })
    },
    // 同步权限到环境
    syncPermsToEnv() {},
    onTabClick() {
      this.getTreeList()
    },
    getCheckedKeys(treeList) {
      // 递归判断 节点 checked = true
      const checkedKeys = []
      function deep(treeList) {
        treeList.forEach(item => {
          if (item.checked) {
            checkedKeys.push(item.sectionId)
          }
          if (item.children && item.children.length > 0) {
            deep(item.children)
          }
        })
      }
      deep(treeList)
      return checkedKeys
    }
  }
}
</script>

<style scoped lang="scss">
.custom-auth {
  .middle {
    .role-list {
      height: 100%;
      .el-tabs {
        height: 100%;
      }
    }
    .tree {
      height: 100%;
      border: 1px solid #eee;
      flex: 1;
      overflow-y: scroll;
      flex-shrink: 0;
      :deep(.el-tree-node__content) {
        .tree-node {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 15px;
          flex: 1;
          flex-shrink: 0;
          overflow: hidden;
          .name {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
