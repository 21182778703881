<template>
  <div class="custom-auth">
    <div class="header">
      <el-button type="primary" size="mini" @click="command({ type: 'add', node: {} })">新建分组</el-button>
      <el-button type="danger" size="mini" @click="onResetCache">重置Cache</el-button>
    </div>
    <div class="middle">
      <div class="tree">
        <el-tree
          ref="tree"
          empty-text="暂无权限分组"
          :data="data"
          :props="defaultProps"
          :default-expand-all="true"
          :highlight-current="true"
          :current-node-key="currentNodeKey"
          node-key="sectionId"
          draggable
          :expand-on-click-node="false"
          @node-click="nodeClick"
          @node-drop="nodeDrop"
        >
          <template slot-scope="{ node }">
            <div class="tree-node">
              <div class="name" :title="node.label">{{ node.label }}</div>
              <el-dropdown szie="mini" trigger="click" @command="command">
                <i class="el-icon-more-outline" style="transform: rotate(90deg);" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{ type: 'add', node: node.data }">新增分组</el-dropdown-item>
                  <el-dropdown-item :command="{ type: 'edit', node: node.data }">编辑</el-dropdown-item>
                  <el-dropdown-item :command="{ type: 'delete', node: node.data }">
                    <span style="color: #f56c6c;">删除</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="detail">
        <div class="button-wrap">
          <el-button
            style="width: 100%;"
            type="primary"
            size="mini"
            @click="editAuthvisible = true"
          >
            添加权限到当前分组
          </el-button>
        </div>
        <div class="list">
          <div v-for="(item, index) in list" :key="index" class="item">
            <div class="item-layout">
              <span v-if="item.permissionType === 1" class="badge">
                VIEW
              </span>
              <span v-else class="badge" style="background-color: black;">
                API
              </span>
              <span>{{ item.name }}</span>
              <span>|</span>
              &nbsp;
              <div class="title" :title="item.permissionTitle">{{ item.permissionTitle }}</div>
              &nbsp;
              <span>|</span>
              <span>【</span>
              <div class="permission" :title="item.permission">{{ item.permission }}</div>
              <span>】</span>
            </div>

            <el-button
              type="danger"
              size="mini"
              @click="handleAction(item.permissionId, 0)"
            >
              移出
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增编辑分组 -->
    <el-dialog
      class="customize-el-dialog"
      :title="`${form.type === 'add' ? '新增' : form.type === 'edit' ? '编辑' : form.type === 'delete' ? '删除' : ''}分组`"
      :visible.sync="formDialogVisible"
      width="400px"
      @close="closeFormDialog"
    >
      <div style="margin: 30px 20px;">
        <span v-if="form.type === 'delete'">确定删除【{{ form.name }}】分组？</span>
        <el-input
          v-else
          ref="formInput"
          v-model="form.name"
          size="mini"
          placeholder="请输入分组名称"
          @keyup.enter="handleSection"
        />
      </div>
      <div slot="footer" style="display: flex; justify-content: center;">
        <el-button size="mini" @click="closeFormDialog">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleSection">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑权限弹窗 -->
    <CustomAuthDialog v-model="editAuthvisible" :current-node-key="currentNodeKey" :parent-list-fn="getSectionDetail" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import CustomAuthDialog from './CustomAuthDialog.vue'
export default {
  name: 'Authority',
  components: {
    CustomAuthDialog
  },
  data() {
    return {
      data: [],
      list: [],
      historyData: [],
      currentNodeKey: '',
      defaultProps: {
        children: 'children',
        label: 'sectionName'
      },
      formDialogVisible: false,
      form: {
        type: '',
        name: '',
        id: ''
      },
      sourceNode: null,
      targetNode: null,
      editAuthvisible: false
    }
  },
  mounted() {
    this.getTreeList()
  },
  methods: {
    getTreeList() {
      this.$axios.get(this.$API.getPermissionList).then(data => {
        this.data = cloneDeep(data)
        this.historyData = cloneDeep(data)
        this.getSectionDetail(data[0].sectionId)
      }).catch(err => {
        this.$message({
          type: 'error',
          message: `权限查询错误: ${err.message}`
        })
      })
    },
    command(action) {
      this.formDialogVisible = true
      this.form.type = action.type
      this.form.id = action.node.sectionId || ''
      switch (action.type) {
        case 'edit':
        case 'delete':
          this.form.name = action.node.sectionName || ''
          break
        default:
          break
      }
      this.$nextTick(() => {
        this.$refs.formInput?.focus()
      })
    },
    handleSection() {
      switch (this.form.type) {
        case 'add':
        case 'edit':
          this.$axios.post(this.$API.addOrUpdatePermission, {
            sectionId: this.form.type === 'edit' ? this.form.id : '',
            parentSectionId: this.form.type === 'add' ? this.form.id : '',
            sectionName: this.form.name
          }).then(() => {
            this.$message({
              type: 'success',
              message: `${this.form.type === 'add' ? '新增' : '编辑'}成功`
            })
            this.getTreeList()
            this.closeFormDialog()
          }).catch(err => {
            this.$message({
              type: 'error',
              message: `${this.form.type === 'add' ? '新增' : '编辑'}失败: ${err.message}`
            })
          })
          break
        case 'delete':
          this.$axios.get(this.$API.deletePermission, {
            params: {
              sectionId: this.form.id
            }
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getTreeList()
            this.closeFormDialog()
          }).catch(err => {
            this.$message({
              type: 'error',
              message: `删除失败: ${err.message}`
            })
          })
          break
        default:
          break
      }
    },
    nodeDrop(source, target, type) {
      if (type === 'inner') {
        this.$confirm(`是否将【${source.data.sectionName}】移动到【${target.data.sectionName}】分组下？`, '移动分组', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.setPermissionClass(source.data.sectionId, target.data.sectionId)
        }).catch(() => {
          this.data = cloneDeep(this.historyData)
        })
      } else {
        if (source.data.parentSectionId !== target.data.parentSectionId) {
          this.$confirm(`是否将【${source.data.sectionName}】移动到【${target.data.parentSectionId ? (target.parent.label || '顶级分组') : '顶级分组'}】分组下？`, '移动分组', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.setPermissionClass(source.data.sectionId, target.data.parentSectionId ? (target.parent.key || '') : '')
          }).catch(() => {
            this.data = cloneDeep(this.historyData)
          })
        }
      }
    },
    onResetCache() {
      this.$confirm('是否清除缓存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(this.$API.cleanUpCachePermission).then(() => {
          this.$message.success('重置缓存成功')
        }).catch(() => {
          this.$message.error('重置缓存失败')
        })
      })
    },
    handleAction(id, action) {
      this.$confirm('确认要删除该权限么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const sectionId = this.currentNodeKey
        this.$axios.post(this.$API.assignPermission, {
          sectionId,
          permissionId: id,
          action
        }).then(() => {
          this.$message.success(`${action === 1 ? '加入' : '移出'}成功`)
          this.getSectionDetail()
        }).catch(err => {
          this.$message.error(`操作失败: ${err.message}`)
        })
      })
    },
    setPermissionClass(sectionId, fatherSectionId) {
      this.$axios(this.$API.setPermissionClass, {
        params: {
          sectionId,
          fatherSectionId
        }
      }).then(() => {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.getTreeList()
      }).catch(err => {
        this.$message({
          message: `设置失败: ${err.message}`,
          type: 'success'
        })
      })
    },
    nodeClick(data) {
      this.getSectionDetail(data.sectionId)
    },
    getSectionDetail(id = this.currentNodeKey) {
      this.$axios.get(this.$API.sectionPermissionDetail, {
        params: {
          sectionId: id
        }
      }).then(data => {
        this.list = data
        this.currentNodeKey = id
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(id)
        })
      }).catch(err => {
        this.$message({
          type: 'error',
          message: `权限查询错误: ${err.message}`
        })
      })
    },
    closeFormDialog() {
      if (this.form.type === 'move') {
        this.sourceNode = null
        this.targetNode = null
      }
      this.formDialogVisible = false
      this.form = {
        type: '',
        name: '',
        id: ''
      }

    },
    getCheckedKeys(treeList) {
      // 递归判断 节点 checked = true
      const checkedKeys = []
      function deep(treeList) {
        treeList.forEach(item => {
          if (item.checked) {
            checkedKeys.push(item.sectionId)
          }
          if (item.children && item.children.length > 0) {
            deep(item.children)
          }
        })
      }
      deep(treeList)
      return checkedKeys
    }
  }
}
</script>

<style scoped lang="scss">
.custom-auth {
  .middle {
    .tree {
      height: 100%;
      border: 1px solid #eee;
      width: 350px;
      overflow-y: scroll;
      flex-shrink: 0;
      :deep(.el-tree-node__content) {
        .tree-node {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 15px;
          flex: 1;
          flex-shrink: 0;
          overflow: hidden;
          .name {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .detail {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid #eee;
      border-left: none;
      flex: 1;
      display: flex;
      flex-direction: column;
      .button-wrap {
        padding: 10px;
        background-color: #fff;
        position: sticky;
        top: 0;
      }
      .list {
        flex: 1;
        overflow-x: auto;
        margin: 5px;
        .item {
          font-size: 14px;
          padding: 5px;
          height: 40px;
          display: flex;
          align-items: center;
          transition: all 0.3s;
          border-radius: 3px;
          >.item-layout {
            display: flex;
            align-items: center;
            flex: 1;
            .badge {
              background-color: #1e9fff;
              padding: 2px 8px;
              color: #fff;
              border-radius: 2px;
              margin-right: 10px;
              font-size: 12px;
            }
            .title,
            .permission {
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &:hover {
            background-color: #ebf5f4;
          }
        }
      }
    }
  }
}
</style>
