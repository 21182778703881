
<template>
  <el-dialog
    :visible.sync="visible"
    class="customize-el-dialog"
    title="添加权限"
    @open="getDataList"
    @closed="reset"
  >
    <div class="custom-auth-detail">
      <el-form inline size="mini">
        <el-form-item>
          <el-input
            v-model="searchParams.searchKey"
            v-debounce="[getDataList, `input`]"
            placeholder="请输入关键字"
            style="width: 200px;"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model="searchParams.permissionType" size="mini" @change="getDataList">
            <el-radio-button :label="1">VIEW</el-radio-button>
            <el-radio-button :label="2">API</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="loading"
        size="mini"
        class="custom-table"
        :data="dataList"
        border
        :height="400"
        :header-cell-style="{ backgroundColor: '#f2f2f2' }"
      >
        <el-table-column
          prop="permissionTitle"
          label="名称"
          header-align="center"
        />
        <el-table-column
          prop="permission"
          label="权限"
          header-align="center"
        />
        <el-table-column
          prop="address"
          label="类型"
          width="50"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.permissionType === 1" style="color: #f56c6c;">
              VIEW
            </span>
            <span v-else style="color: #67c23a;">
              API
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="50"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleAction(scope.row.permissionId, 1)">加入</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'CustomAuthDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentNodeKey: {
      type: String,
      default: ''
    },
    parentListFn: {
      type: Function,
      default() {
        return () => { }
      }
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      searchParams: {
        searchKey: '',
        permissionType: 1
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getDataList() {
      let sectionId = this.currentNodeKey
      const params = {
        sectionId,
        ...this.searchParams
      }
      this.loading = true
      this.$axios.get(this.$API.unAssignPermissionList, { params }).then(data => {
        this.dataList = data
      }).catch(err => {
        this.$message.error(`获取未分组权限失败: ${err.message}`)
      }).finally(() => {
        this.loading = false
      })
    },
    handleAction(id, action) {
      const sectionId = this.currentNodeKey
      let params = {
        sectionId,
        permissionId: id,
        action
      }
      this.$axios.post(this.$API.assignPermission, params).then(() => {
        this.getDataList()
        this.parentListFn()
        this.$message.success(`${action === 1 ? '加入' : '移出'}成功`)
      }).catch(err => {
        this.$message.error(`操作失败: ${err.message}`)
      })
    },
    reset() {
      this.searchParams.searchKey = ''
    }
  }
}
</script>

<style scoped lang="scss">
.custom-auth-detail {
  padding: 0 10px 10px;
  .custom-table {
    width: 100%;
  }
}
</style>
