<template>
  <el-tabs v-model="activeName" class="custom-tabs">
    <el-tab-pane label="权限数据管理" name="authority">
      <Authority />
    </el-tab-pane>
    <el-tab-pane label="角色数据管理" name="role">
      <Role />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Authority from './components/authority.vue'
import Role from './components/role.vue'
export default {
  name: 'CustomAuth',
  components: {
    Authority,
    Role
  },
  data() {
    return {
      activeName: 'authority'
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-tabs {
  >:deep(.el-tabs__header) {
    .el-tabs__nav-scroll {
      padding-right: 300px;
    }
  }
  :deep(.el-tabs__content) {
    overflow: unset;
    position: relative;
    .custom-auth {
      .header {
        position: absolute;
        right: 0;
        top: -50px;
        z-index: 10;
      }
      .middle {
        display: flex;
        height: calc(100vh - 75px);
        min-height: 300px;
      }
    }
  }
}
</style>
